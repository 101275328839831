import React from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import ListItemText from "@mui/material/ListItemText";
import IosShareIcon from "@mui/icons-material/IosShare";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LogoImg from "../../assets/SquareLogo.png";
import { ListSubheader } from "@mui/material";
import { Face, Publish, Notifications, Build, Favorite } from "@mui/icons-material";
import ComingSoonFeature from "../../components/ComingSoonFeature/ComingSoonFeature";
import DisplayText from "../../components/DisplayText/DisplayText";
import { Box } from "@mui/system";
import usePermissions from "../permissions/usePermissions";
import { nebulaClient2 } from "../../lib/nebulaClient";
import { useSnackbar } from "notistack";
import useRoles from "../permissions/useRoles";
import { useAppSelector } from "../../app/hooks";
import { getSelectedTrial } from "../auth/login/loginSlice";

interface IMenuProps {
    onClose: (e: React.MouseEvent) => void;
}
const Menu: React.FC<IMenuProps> = ({ onClose }) => {
    const { hasPermissions } = usePermissions();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { userHasRole } = useRoles();

    const selectedTrial = useAppSelector(getSelectedTrial);

    const downloadFileBlob = (blob: Blob, fileName: string) => {
        const url = window.URL.createObjectURL(blob);

        // Crete link element
        const link = document.createElement("a");
        link.href = url;

        // get trial name from redux store
        link.setAttribute("download", fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link!.parentNode!.removeChild(link);
    };

    const handleExportCensus = async () => {
        if (!selectedTrial) return;

        enqueueSnackbar("Generating Census report...", { variant: "info" });

        try {
            const fileData = await nebulaClient2.get(`report/${selectedTrial.uuid}/census?filterColumns=fileName`);

            const fileName = `${selectedTrial.name}_Census_${new Date().toISOString()}.csv`;

            // @ts-ignore
            downloadFileBlob(new Blob([fileData.data]), fileName);

            closeSnackbar();
        } catch (e) {
            closeSnackbar();
            enqueueSnackbar("Something went wrong", { variant: "error" });
        }
    };

    const handleExportCrf = async () => {
        if (!selectedTrial) return;

        enqueueSnackbar("Generating CRF report...", { variant: "info" });

        try {
            const fileData = await nebulaClient2.get(`trial/${selectedTrial.uuid}/crf`);

            const fileName = `${selectedTrial.name}_CRF_${new Date().toISOString()}.csv`;

            // @ts-ignore
            downloadFileBlob(new Blob([fileData.data]), fileName);

            closeSnackbar();
        } catch (e) {
            closeSnackbar();
            enqueueSnackbar("Something went wrong", { variant: "error" });
        }
    };

    return (
        <Box
            sx={{
                width: 300,
                height: "100%",
                backgroundColor: "#2f2f2f",
                display: "flex",
                flexDirection: "column",
            }}
            role="presentation"
        >
            <List
                sx={{ pb: 0 }}
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <Box sx={{ my: 20, display: "flex" }}>
                                <img style={{ maxHeight: 50 }} src={LogoImg} alt="Voxeleron Logo" />
                            </Box>

                            <Box>
                                <DisplayText
                                    type="h5"
                                    variant="regular"
                                    text="iNebula"
                                    style={{ marginBottom: "0px" }}
                                />
                                <DisplayText
                                    type="bodyXSmall"
                                    variant="regular"
                                    text="by Voxeleron"
                                    style={{ marginBottom: "0px" }}
                                />
                            </Box>
                        </Box>
                    </ListSubheader>
                }
            />
            <Divider />
            <List onClick={onClose}>
                <Link
                    to={{
                        pathname: "/patients",
                    }}
                >
                    <ListItem button key={"Review Patients"}>
                        <ListItemIcon>
                            <Face style={{ color: "#fe8c03" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Review Patients"} />
                    </ListItem>
                </Link>
                {hasPermissions(["Upload"]) && (
                    <Link
                        to={{
                            pathname: "/upload",
                        }}
                    >
                        <ListItem button key={"Upload Images"}>
                            <ListItemIcon>
                                <Publish style={{ color: "#fe8c03" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Upload Images"} />
                        </ListItem>
                    </Link>
                )}
                <ComingSoonFeature>
                    <ListItem disabled button key={"Check Notifications"}>
                        <ListItemIcon>
                            <Notifications style={{ color: "#fe8c03" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Check Notifications"} />
                    </ListItem>
                </ComingSoonFeature>
                <ComingSoonFeature>
                    <ListItem disabled button key={"Manage System"}>
                        <ListItemIcon>
                            <Build style={{ color: "#fe8c03" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Manage System"} />
                    </ListItem>
                </ComingSoonFeature>
                <ComingSoonFeature>
                    <ListItem disabled button key={"Edit configration and profile"}>
                        <ListItemIcon>
                            <Favorite style={{ color: "#fe8c03" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Edit configration and profile"} />
                    </ListItem>
                </ComingSoonFeature>
                {userHasRole("PROJECT_MANAGER") && (
                    <Link
                        to={{
                            pathname: "/dashboard",
                        }}
                    >
                        <ListItem button key={"Dashboard"}>
                            <ListItemIcon>
                                <SsidChartIcon style={{ color: "#fe8c03" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Dashboard"} />
                        </ListItem>
                    </Link>
                )}
                {hasPermissions(["Delete"]) && (
                    <Box onClick={handleExportCrf}>
                        <ListItem disabled={!selectedTrial} button key={"Export CRF data"}>
                            <ListItemIcon>
                                <IosShareIcon style={{ color: "#fe8c03" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Export CRF data"} />
                        </ListItem>
                    </Box>
                )}
                {hasPermissions(["Delete"]) && (
                    <Box onClick={handleExportCensus}>
                        <ListItem disabled={!selectedTrial} button key={"Export Census"}>
                            <ListItemIcon>
                                <ListAltIcon style={{ color: "#fe8c03" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Export Census"} />
                        </ListItem>
                    </Box>
                )}
            </List>
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "end",
                    p: 5,
                }}
            >
                <DisplayText type="bodyXSmall" text="v1.10.0" />
            </Box>
        </Box>
    );
};

export default Menu;
